.autocomplete--suggestion {
	padding: 10px 10px !important;
	text-align: left;
	border-bottom: 1px solid #eeeeee;
}

.autocomplete--suggestion-hover {
	background-color: #D9ECF0;
	color: #02355A;
	cursor: pointer;
}
