.edit-project {
  position: relative;
  margin-top: 10px;
  font-size: 14px;

  .loading-overlay {
    height: calc(100% - 57px);
    width: calc(33%);
    z-index: 100000;
    background: #ffffff;
    opacity: 0.6;
    position: fixed;
    top: 57px;
    left: 0;
  }

  p,
  h4,
  h6 {
    margin: 0;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .project-input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
      width: fit-content;

      span {
        color: #1e5af6;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    p {
      font-size: 12px;
      color: #333333;
      margin-top: 5px;
    }

    .project-input {
      input,
      textarea,
      select {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        margin-bottom: 15px;
        border: 1px solid #eee;
        border-radius: 5px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        transition: 0.3s;

        &.invalid {
          border: 1px solid #c42527;
          background: #ffc4c5;

          &::placeholder {
            color: #d1595b;
          }
        }
      }

      select.invalid {
        color: #d1595b !important;
      }

      i,
      img {
        position: absolute;
        top: 38px;
        left: 10px;
        width: 20px;
      }

      button {
        border: 0;
        padding: 5px;
        font-size: 12px;
        background: #1d5af6;
        color: #ffffff;
        border-radius: 5px;
      }

      .form-control {
        height: auto;
        margin-bottom: 0;
        box-shadow: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: #000000;

        &.invalid {
          input {
            border: 1px solid #c42527 !important;

            &::placeholder {
              color: #d1595b !important;
            }
          }
        }
      }

      .rbt-menu {
        border: 1px solid #eeeeee;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        top: -2px !important;

        .dropdown-item {
          padding: 5px 10px;
        }
      }
    }

    .project-description-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .planning-checkbox {
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          margin: 0 5px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .project-predictions-wrapper {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;

    h4 {
      width: fit-content;
      cursor: pointer;
    }

    .project-prediction {
      width: 100%;
      height: auto;
      padding: 10px;
      margin: 15px 0;
      border: 2px solid #eeeeee;
      border-radius: 5px;
      box-shadow: 0px 1px 5px rgba(100, 100, 100, 0.2);
      background-color: white;
      font-family: "Lato";
      font-size: 14px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transition: 0.3s;
      position: relative;

      &:hover {
        transform: scale(1.03);
        border: 2px solid #1e5af6;
      }

      &.active {
        border: 2px solid #1e5af6;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        font-style: italic;
      }

      i {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      span {
        font-weight: 900;
        font-size: 14px;
      }
    }
  }

  .project-details-wrapper {
    margin-bottom: 20px;

    .project-details-text {
      padding: 10px;
    }

    .image-toggle {
      max-width: fit-content;
      margin: auto;

      p {
        color: #1d5af6;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .project-details-image {
      align-items: center;
      justify-content: center;
      display: none;

      &.show {
        display: flex;
        margin-bottom: 20px;
      }

      img {
        width: 90%;
      }
    }

    .project-quality-wrapper {
      margin: 20px 0;

      .project-quality {
        width: 100%;
        height: auto;
        margin: 15px 0;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        box-shadow: 0px 1px 5px rgba(100, 100, 100, 0.2);
        background-color: white;
        font-family: "Lato";
        font-size: 14px;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
        position: relative;

        &.invalid {
          border: 1px solid #c42527;
          background: #ffc4c5;

          .quality-header {
            background: #ff9799;

            p,
            span {
              color: #c42527;
            }
          }
        }

        &.active {
          border: 2px solid #1e5af6;

          .quality-header {
            p,
            span {
              color: #1e5af6;
            }
          }

          .quality-costs {
            i {
              color: #1e5af6;
            }
          }
        }

        &.show {
          .quality-description {
            border-bottom: 1px solid #eeeeee;
            margin-bottom: 10px;
          }

          .quality-costs {
            display: inline;

            table {
              td {
                a {
                  text-transform: uppercase;
                  font-size: 12px;
                }
              }
            }
          }
        }

        .quality-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          background: #eeeeee;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          font-weight: 900;

          p,
          span {
            cursor: pointer;

            &:hover {
              color: #1e5af6;
              text-decoration: underline;
            }
          }
        }

        .quality-body {
          padding: 10px;
        }

        .quality-description {
          padding-bottom: 5px;
        }

        .quality-costs {
          display: none;

          input {
            max-width: 80px;
            max-height: 20px;
          }

          i {
            font-size: 16px;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
              color: #1e5af6;
            }
          }
        }

        table {
          width: 100%;
        }
      }
    }
  }

  .project-footer {
    margin-top: 20px;

    button {
      width: 100%;
      padding: 5px 2px;
      border: 0;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
}
