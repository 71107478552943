.autocomplete--suggestions {
    position: absolute;
    top: 39px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 200px;
    overflow: scroll;
    z-index: 1;
}