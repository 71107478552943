.autocomplete--input-label {
	display: inline-block;
	font-family: Helvetica, Arial, sans-serif;
	padding-right: 15px;
	text-align: right;
	width: 200px;
}

.autocomplete--input-field {
	display: inline-block;
	width: 100%;
	padding: 10px 30px;
	border: 1px solid #cccccc;
	border-radius: 5px;
}