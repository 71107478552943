.modal-90w {
    width: 80%;
	max-width: none!important;
}

.lock-screen {
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1060;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}