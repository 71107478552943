.md-login-page {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    .md-login-form-wrapper {
        max-width: 400px;
        width: 100%;
    }

    img {
        margin-top: -60px;
        margin-bottom: 20px;
        width: 200px;
    }

    button {
        width: 100%;
    }
}